import { CartIcon } from '@components/icons/CartIcon';
import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { useAppContext } from '@lib/context/appContext';
import { State } from '@model/state';
import { useEffect } from 'react';

type Props = {
  baseUrl: string;
  locale: string;
};

export const CartToggle: React.FC<Props> = ({ baseUrl, locale }) => {
  const { client, dispatch, state } = useAppContext() as {
    client: AppCommerceLayerClient;
    dispatch: any;
    state: State;
  };

  const checkItems = async () => {
    const order = state.order ?? (await client.getOrder());
    dispatch({ type: 'SET_ORDER', data: order });
    const cartItems = client.countOrderProductsQuantity(order);
    dispatch({ type: 'SET_NUM_ITEMS', data: cartItems });
  };

  useEffect(() => {
    if (client) {
      checkItems();
    }
  }, [client]);

  return (
    <div className="cart_nav">
      <a href={`${baseUrl}/${locale}/cart`} className="cart_link" title="cart">
        <CartIcon width="28px" height="28px" />
        <span className="cart_number">{state?.numItems ?? 0}</span>
      </a>
    </div>
  );
};

export default CartToggle;
