import { AppCommerceLayerClient } from '@lib/AppCommerceLayerClient';
import { useAppContext } from '@lib/context/appContext';
import t, { Labels } from '@lib/locales';
import {
  filterCountries,
  filterMarketByCountryCode,
  getMarketFromPathname,
  getPathFromMarket,
} from '@lib/utils/clUtils';
import { getLocales, isHome } from '@lib/utils/pathUtils';
import { Locales } from '@model/common';
import { State } from '@model/state';
import { COUNTRY_CODE_KEY, MARKET_ID_KEY } from '@shared/constants';
import { localStorageGetItem, localStorageSetItem } from '@utils/local-storage';
import { deleteCartIdSession, deleteUserSession } from '@utils/session-storage';
import _ from 'lodash';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';

type Props = {
  locale: Locales;
};

export const MarketSelector: React.FC<Props> = ({ locale }) => {
  const { state, dispatch, client } = useAppContext() as {
    state: State;
    dispatch: any;
    client: AppCommerceLayerClient;
  };
  const {
    countryCode: promotedCountry,
    countryModal: isActive,
    projectName,
    hasMultipleCountries,
  } = state;
  const [country, setCountry] = useState(promotedCountry);
  const [options] = useState(() => filterCountries(promotedCountry));
  const [currency, setCurrency] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState<string[] | null>(null);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const locales = getLocales();
  const pathname = usePathname();
  const router = useRouter();
  const searchParams = useSearchParams();

  function redirect(marketId: string, locale: Locales) {
    if (!marketId) {
      return;
    }
    const redirectUrl = getPathFromMarket(marketId, locale);
    if (pathname.indexOf(redirectUrl || '') < 0) {
      const search = searchParams.toString();
      const url = `${redirectUrl}${search ? `?${search}` : ''}`;
      router.push(url);
    }
  }

  //on market selected
  function handleMarketChange(marketId: string, countryCode: string, locale: Locales) {
    const data = { marketId, countryCode };
    if (client) {
      client.cleanup();
    } else {
      deleteCartIdSession();
      deleteUserSession();
    }
    localStorageSetItem(`${projectName}${MARKET_ID_KEY}`, marketId);
    localStorageSetItem(`${projectName}${COUNTRY_CODE_KEY}`, countryCode);
    dispatch({ type: 'INIT_MARKET', data });

    redirect(marketId, locale);
  }

  useEffect(() => {
    //check if have multiple market setted
    if (typeof hasMultipleCountries !== 'undefined') {
      const savedMarketId = localStorageGetItem(`${projectName}${MARKET_ID_KEY}`);
      if (!savedMarketId) {
        if (hasMultipleCountries) {
          //dispatch modal only if path is home
          if (isHome(pathname)) {
            // DIALOG
            dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: true });
          } else {
            const urlMarket = getMarketFromPathname(pathname);
            if (urlMarket) {
              // CHANGE TO MARKET IN CURRENT URL
              handleMarketChange(urlMarket.marketId, urlMarket.countryCode, locale);
            }
          }
        }
      } else {
        const marketBaseUrl = getPathFromMarket(savedMarketId, locale);
        const isDifferent = pathname.indexOf(marketBaseUrl || '') < 0;

        if (isDifferent) {
          if (!isHome(pathname)) {
            const market = getMarketFromPathname(pathname);
            handleMarketChange(market.marketId, market.countryCode, locale);
          } else {
            redirect(savedMarketId, locale);
          }
        }
      }
    }
  }, [hasMultipleCountries]);

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    if (value) {
      setCountry(value);
      const m = filterMarketByCountryCode(value);
      const currencies = m.reduce((all: string[], m) => {
        return _.uniq([...all, m.currency]);
      }, []);
      setCurrency('');
      setCurrencyOptions(currencies && currencies.length > 1 ? currencies : null);
    }
  };

  const handleClose = () => {
    dispatch({ type: 'TOGGLE_COUNTRY_MODAL', data: false });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!country) {
      return;
    }
    const m = filterMarketByCountryCode(country);
    let market = m[0];
    if (country && currency) {
      market = m.find((i) => i.currency === currency);
    }
    if (market) {
      handleMarketChange(market.market_id, country, selectedLocale);
      handleClose();
    }
  };

  return (
    <div className={`market-dialog ${isActive ? 'is-active' : ''}`}>
      <div className="market-dialog__frame">
        <div className="market-dialog__header">{t(locale, 'choose_shipping_country')}</div>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="market-dialog__body">
            <div className="market-dialog__select">
              <select
                className="market-dialog__select__inner"
                onChange={(e) => handleChange(e)}
                value={country}
              >
                {!promotedCountry && (
                  <option value="">{t(locale, 'choose_shipping_country')}</option>
                )}

                {options
                  .map((ops) => {
                    return {
                      code: ops.value,
                      label: t(locale, `countries.${ops.value}` as Labels),
                    };
                  })
                  .sort((a, b) => (a.label > b.label ? 1 : -1))
                  .map((o) => (
                    <option key={o.code} value={o.code}>
                      {o.label}
                    </option>
                  ))}
              </select>
            </div>

            {currencyOptions && (
              <div className="market-dialog__select">
                <select
                  className="market-dialog__select__inner"
                  onChange={(e) => setCurrency(e.target.value)}
                  value={currency}
                >
                  {currencyOptions.map((currency) => (
                    <option key={currency} value={currency}>
                      {currency}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {locales && (
              <>
                <div className="market-dialog__header"> {t(locale, 'choose_locale')}</div>
                <div className="market-dialog__select">
                  <select
                    className="market-dialog__select__inner"
                    value={selectedLocale}
                    onChange={(e) => setSelectedLocale(e.target.value as Locales)}
                  >
                    {locales.map((locale) => (
                      <option key={locale} value={locale}>
                        {t(locale as Locales, locale as Labels)}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            <div className="market-dialog__footer">
              <button type="submit" className="button--primary">
                {t(locale, 'select')}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
