import { getLocales } from '@lib/utils/pathUtils';
import { AltsType, Locales } from '@model/common';
import Image from 'next/image';

type Props = {
  locale: Locales;
  baseUrl: string;
  alts?: AltsType;
};

export const LanguageSelector: React.FC<Props> = ({ locale, baseUrl, alts }) => {
  const langs = getLocales();
  const otherLangs = langs.filter((l) => l !== locale) as Locales[];

  if (langs.length < 2) {
    return null;
  }

  return (
    <div className="locale-switcher">
      <a href="#" className="locale-switcher__link" title={locale}>
        <Image
          width={20}
          height={20}
          className="locale-switcher__image"
          src={`/images/flags/${locale}.svg`}
          alt={locale}
        />
      </a>
      <div className="locale-switcher__dropdown">
        {otherLangs.map((locale: Locales) => {
          let alternativeUrl = `${baseUrl}/${locale}`;

          if (alts && alts[locale]) {
            const { path } = alts[locale];
            const endPath = path ? `/${path}` : '';
            alternativeUrl = `${baseUrl}/${locale}${endPath}`;
          }

          return (
            <a
              href={alternativeUrl}
              className="locale-switcher__dropdown__link"
              title={locale}
              key={locale}
            >
              <Image
                width={50}
                height={20}
                className="locale-switcher__dropdown__image"
                src={`/images/flags/${locale}.svg`}
                alt={locale}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
};
