import { Locales } from '@model/common';
import axios from 'axios';

export const searchContents = async (
  market: string,
  currency: string,
  locale: Locales,
  query: string
) => {
  const url = `/api/${market}/${currency}/${locale}/search/${query}`;
  const res = await axios.get(url);
  return res.data;
};
